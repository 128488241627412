<template>
  <div class="card-title card-title-jaya">
    <div v-if="duotuneIcon" class="card-icon">
      <span class="svg-icon" :class="small ? 'svg-icon-2x' : ''">
        <inline-svg
          :src="
            require('@/../public/media/icons/duotune/' + duotuneIcon + '.svg')
          "
        />
      </span>
    </div>

    <div>
      <h2 v-if="title" class="pe-3" :class="small ? 'fs-4' : ''">
        {{ title }}
      </h2>
      <div v-if="subTitle" class="fs-4 fw-normal fst-italic text-secondary">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-header-jaya",
  props: {
    title: String,
    subTitle: String,
    duotuneIcon: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
